import React from 'react';
import { sortBy } from 'lodash';
import { cleanText, queryParameters, setFocus } from '../utilities';

const images = require.context('../../../images/', true);

// Get language but fallback to default if the value is bad
export function getLanguage(params) {
    let language = 'en';
    if (['en', 'es'].includes(params.get('language'))) {
        language = params.get('language');
    }

    return language;
}

// Get stop type but fallback to default if the value is bad
export function getType(params) {
    let type = 'all';
    if (['all', 'kids', 'access'].includes(params.get('type'))) {
        type = params.get('type');
    }

    return type;
}

export function getTypeIcon(type) {
    // [type, path]
    const icons = [
        ['audio', './audio_play.svg'],
        ['asl', './access_asl.svg'],
        ['transcription', './access_transcript.svg'],
        ['verbal_description', './access_verbal_description.svg']];
    const icon = icons.find((array) => type === array[0]);
    if (icon) {
        return <img src={ images(icon[1]) } className={ `guide-type-icon guide-type-icon--${ type }` } alt=""/>;
    }

    return null;
}

// Generates strings like "Floors 1, 3" with proper pluralization
export function getLocationLabel(language, locations) {
    if (locations.length === 0) {
        return;
    }

    // Only show floors
    const allowedURIs = ['basement-1', '1', '3', '4', '5', '6', '7', '8'];
    const allowedLocations = locations.filter(location => allowedURIs.includes(location.data.attributes.uri)).map(location => location.data.attributes.short_name);
    const uniqueLocations = [...new Set(allowedLocations)].sort((a, b) => a - b);

    return `${ I18n[language].floor }${ uniqueLocations.length > 1 ? 's' : '' } ${ uniqueLocations.join(', ') }`;
}

export function getLocationIcons(language, locations, currentLocation) {
    const tags = [];
    const locationFeatures = new Set();
    const location = locations.filter((locationObj) => locationObj.data.attributes.name === currentLocation)[0];

    if (!location) {
        return tags;
    }

    if (location.data.attributes.bathroom && !locationFeatures.has('bathroom')) {
        locationFeatures.add('bathroom');
        tags.push(<img key={ tags.length } src={ images('./restroom.svg') }
                       className="guide-location-icons__icon"
                       aria-label={ I18n[language].restroom }/>);
    }

    if ((location.data.attributes.restaurant || location.data.attributes.cafe) && !locationFeatures.has('dining')) {
        locationFeatures.add('dining');
        tags.push(<img key={ tags.length } src={ images('./dining.svg') }
                       className="guide-location-icons__icon" aria-label={ I18n[language].food }/>);
    }

    if (location.data.attributes.shop && !locationFeatures.has('shop')) {
        locationFeatures.add('shop');
        tags.push(<img key={ tags.length } src={ images('./shop.svg') }
                       className="guide-location-icons__icon" aria-label={ I18n[language].shop }/>);
    }

    if (location.data.attributes.coat && !locationFeatures.has('coat')) {
        locationFeatures.add('coat');
        tags.push(<img key={ tags.length } src={ images('./coat_check.svg') }
                       className="guide-location-icons__icon"
                       aria-label={ I18n[language].coat_check }/>);
    }

    return tags;
}

export const guideStopItemAccessTypes = [
    'asl',
    'transcription',
    'audio_description',
    'sound_description',
    'verbal_description',
];

export function isAccessType(itemType) {
    return guideStopItemAccessTypes.includes(itemType);
}

// Given an array of exhibitions determine if any are current
export function hasCurrentExhibition(exhibitions) {
    const { searchParams } = new URL(window.location.href);
    let now = searchParams.get('date');
    if (!now) {
        now = new Date();
    }

    return exhibitions.some(exhibition => {
        const startTime = new Date(exhibition.data.attributes.start_time);
        const endTime = new Date(exhibition.data.attributes.end_time);
        return now >= startTime && now <= endTime;
    });
}

// Get all stop items for the requested stop, preferencing the requested language
export function getStopItems(stop, language, fallback = true) {
    if (!stop) return [];

    let stopItemsOfLanguage = stop
        .attributes
        .guide_stop_items
        .filter((stopItem) => {
            const { item_language: itemLanguage, item_type: itemType } = stopItem.data.attributes;
            let test = itemLanguage === language;

            // Fall back on English
            if (fallback && !test && language !== 'en' && itemLanguage === 'en') {
                // Include this in Spanish results if there are no items of this type in Spanish
                test = stop
                    .attributes
                    .guide_stop_items
                    .filter((stopItemTest) => (
                        stopItemTest.data.attributes.item_language === 'es' &&
                        stopItemTest.data.attributes.item_type === itemType
                    )).length === 0;
            }
            return test;
        });

    const typeSortOrder = {
        general: 1,
        audio: 1,
        kids: 2,
        access: 3,
    };

    stopItemsOfLanguage = sortBy(
        stopItemsOfLanguage,
        (item) => typeSortOrder[item.data.attributes.item_type] || 4,
    );

    return stopItemsOfLanguage;
}

// filter for stops that have at least one item in the desired language and type
// if searching for access items, return all the different access item_types
export function getStopsContainingType(guide, language, type, fallback = true) {
    const stops = guide.attributes.guide_stops;

    return stops.filter((stop) => {
        const itemsOfLanguage = getStopItems(stop.data, language, fallback);
        const itemsOfLanguageAndType = itemsOfLanguage.filter((stopItem) => {
            const thisItemType = stopItem.data.attributes.item_type;

            return thisItemType === type ||
                (type === 'access' && isAccessType(thisItemType)) ||
                type === 'all';
        });

        return itemsOfLanguageAndType.length;
    });
}

// Might not need? ~ colin
export function itemTypeDisplay(type, language) {
    return I18n[language][type];
}

// Open stop overlay
export function openStopOverlay() {
    // Overlay is only for small screens
    if (window.isNavSmall) {
        const guideSecondary = document.querySelector('.guide__secondary');

        guideSecondary.scrollTop = 0;
        document.body.classList.add('stop-open');
        document.querySelector('.audio-player')?.classList.add('audio-player--expanded');

        guideSecondary.classList.add('active');
        // Set focus to the back button so focus is on the part of the window that can scroll the image/transcript
        setFocus('.guide-stop-controls__close');
    }
}

export function safelyParseJSON(str) {
    try {
        return JSON.parse(str);
    } catch (ex) {
        return null;
    }
}

export function getBasename() {
    return `/${ window.location.pathname.split('/')[1] }`;
}

export function isMobileGuide() {
    return getBasename() === '/guide';
}

// Push guide event to data layer
// params: { guide_name, label, language, type, value }
export function pushGuideEvent(params = {}, guide = {}) {
    const { language, type } = queryParameters();
    // Combine values stored in URL with overridden values (since the URL may not yet reflect recent changes)
    const values = {
        guide_name: cleanText(guide?.attributes?.title),
        language: language || 'en',
        type,
        ...params,
    };

    if (!useAnalytics) return;

    // GA4
    dataLayer.push({
        event: 'guide',
        guide_name: values.guide_name,
        label: values.label,
        language_choice: values.language,
        type: values.type,
        value: values.value,
    });
}

const CACHE = {};

// load cached data and cache until browser refreshes
export async function fetchWithCache(path) {
    if (CACHE[path] === undefined) {
        try {
            const response = await fetch(path);
            const result = await response.json();
            CACHE[path] = result;

        } catch (error) {
            if (error?.code && ['ETIMEDOUT', 'ECONNABORTED'].includes(error?.code)) {
                console.log('error', error);
                throw new Error(`fetchWithCache Timeout exceeded -- ${ path }`);
            }

            console.log('error.code', error.code);
        }
    }

    return CACHE[path];
}

import React from 'react';
import { sortBy } from 'lodash';

import GuideLink from '../../components/GuideLink';
import Indicators from './Indicators';
import { cleanText, createMarkup } from '../../../utilities';
import { getLocationIcons, pushGuideEvent } from '../../utilities';

export default function GuideListItems({ guides, language }) {
    const listItems = [];
    const sortedGuides = {};

    const handleGuideClick = (guide) => {
        const guideName = cleanText(guide?.attributes.title);

        pushGuideEvent({ guide_name: guideName, label: 'guide', value: guideName });
    };

    if (!guides.length) {
        return (
            <p className="m-0">
                <strong dangerouslySetInnerHTML={ createMarkup(I18n[language].empty_guides) }/>
            </p>
        );
    }

    // render guides once for each floor they are tagged in
    // sort guides labeled "Institutional" separately
    guides.forEach((guide) => {
        if (guide.attributes.guide_type === 'institutional') {
            sortedGuides.institutional ||= [];
            sortedGuides.institutional.push(guide);
        } else {
            guide.attributes.locations.forEach((locationRecord) => {
                const locationName = locationRecord.data.attributes.short_name || '0';
                sortedGuides[locationName] ||= [];
                sortedGuides[locationName].push(guide);
            });
        }
    });

    const locations = Object.keys(sortedGuides).sort().slice().reverse();
    locations.forEach((locationName, locationIndex) => {
        const guidesSortedByDate = sortBy(
            sortedGuides[locationName],
            [(guide) => guide.attributes.sort_date],
        ).slice().reverse();

        guidesSortedByDate.forEach((guide, index) => {
            const artwork = guide.attributes.guide_type === 'artwork';
            const disabled = guide.attributes.guide_stops.length === 0;
            const showLocation = index === 0 && locationName !== 'institutional';

            let media;
            if (guide.attributes.primary_media_html) {
                media = <div className={ `guide-list-item__media${ artwork ? ' artwork' : '' }` }
                             dangerouslySetInnerHTML={ createMarkup(guide.attributes.primary_media_html) }/>;
            }

            let eyebrow;
            if (artwork) {
                eyebrow = <p className="guide-list-item__type body-medium-bold">
                    { I18n[language][guide.attributes.guide_type] }
                </p>;
            }

            const guideMainContent =
                <>
                    { media }
                    { eyebrow }
                    <h2 className={ `guide-list-item__title${ disabled ? ' gray' : '' }` }
                        dangerouslySetInnerHTML={ createMarkup(guide.attributes.title) }/>
                    <p className={ `guide-list-item__indicators${ disabled ? ' gray' : '' }` }>
                        { guide.attributes.guide_stops.length > 0
                            ? <Indicators guide={ guide } language={ language }/>
                            : I18n[language].empty_guides
                        }
                    </p>
                </>;

            let guideMain;
            if (guide.id) {
                guideMain =
                    <GuideLink
                        to={ `${ guide.id }?language=${ language }` }
                        onClick={ () => handleGuideClick(guide) }
                        scrollToTop={ true }
                        className="guide-list-item__main"
                    >
                        { guideMainContent }
                    </GuideLink>;
            } else {
                guideMain =
                    <div className="guide-list-item__main">
                        { guideMainContent }
                    </div>;
            }

            const listItem =
                <li key={ `${ locationIndex }-${ index }` }
                    className={ `guide-list-item${ showLocation ? ' guide-list-item--line' : '' }` }>
                    <hr className="guide-list-item__line"/>
                    <div className={ `guide-list-item__floor${ showLocation ? ' active' : '' }` }>
                        <h3 className="guide-list-item__floor-number m-0"
                            aria-label={ `${ I18n[language].floor } ${ locationName }` }>
                            { showLocation ? locationName : '\u00A0' }
                        </h3>
                        <div className="guide-location-icons guide-location-icons--list">
                            { getLocationIcons(language, guide.attributes.locations, locationName) }
                        </div>
                    </div>
                    { guideMain }
                </li>;

            listItems.push(listItem);
        });
    });

    return <>{ listItems }</>;
}

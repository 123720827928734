//=================================================
// Guide pagination
//=================================================

import React from 'react';
import { isMobileGuide } from '../../utilities';
import PaginationLink from './PaginationLink';

export function ArrowLeftIcon({ className }) {
    return (
        <svg
            className={ className }
            width="36"
            height="48"
            viewBox="0 0 36 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={ {
                stroke: "var(--color-text)",
                strokeWidth: 1.5
            } }
        >
            <polyline points="32.8 3 3.2 24 32.8 45"/>
        </svg>
    );
}

export function ArrowRightIcon({ className }) {
    return (
        <svg
            className={ className }
            width="36"
            height="48"
            viewBox="0 0 36 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={ {
                stroke: "var(--color-text)",
                strokeWidth: 1.5
            } }
        >
            <polyline points="3.2 45 32.8 24 3.2 3"/>
        </svg>
    );
}

export default function Pagination({ page: pageProp, totalGuides, listItemsLength, language }) {
    const page = parseInt(pageProp) || 1;
    const paginating = false;
    const guidesPerPage = 30;
    const totalPages = Math.ceil(totalGuides / guidesPerPage);

    if (isMobileGuide() || !(totalPages > 1 && listItemsLength > 0)) return null;

    const prevButton = () => {
        if (page > 1) {
            return (
                <PaginationLink className="pagination__btn pagination__btn--prev btn btn--icon-only"
                                aria-label="Previous"
                                disabled={ paginating }
                                type="button"
                                page={ page - 1 }>
                    <span className="pagination__text">Previous</span>
                    <ArrowLeftIcon/>
                </PaginationLink>
            );
        }
        return null;
    };

    const nextButton = () => {
        if (page < totalPages) {
            return (
                <PaginationLink className="pagination__btn pagination__btn--next btn btn--icon-only"
                                aria-label="Next"
                                disabled={ paginating }
                                type="button"
                                page={ page + 1 }>
                    <span className="pagination__text">Next</span>
                    <ArrowRightIcon/>
                </PaginationLink>
            );
        }
        return null;
    };

    const paginationItem = (index) => (
        <PaginationLink key={ index } className={ `pagination__btn btn${ index === page ? ' active' : '' }` }
                        disabled={ paginating }
                        type="button"
                        page={ index }
        >
            { index }
        </PaginationLink>
    );

    const paginationDash = (index) => (<span key={ index } className="pagination__dash">&mdash;</span>);

    let hyphen = false;
    const pageNumberArray = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumberArray.push(i);
    }
    const pageNumbers = pageNumberArray.map((index) => {
        if (totalPages >= 5) {
            switch (page) {
                case 1:
                    if (index <= 2 || index === totalPages) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case 2:
                    if (index <= 3 || index === totalPages) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case 3:
                    if (index <= 4 || index === totalPages) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case totalPages:
                    if (index >= totalPages - 1 || index === 1) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case totalPages - 1:
                    if (index >= totalPages - 2 || index === 1) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                case totalPages - 2:
                    if (index >= totalPages - 3 || index === 1) {
                        return paginationItem(index);
                    }
                    if (!hyphen) {
                        hyphen = true;
                        return paginationDash(index);
                    }
                    return null;

                default:
                    return paginationItem(index);
            }

        } else {
            return paginationItem(index);
        }
    });

    return (
        <div className={ 'pagination-wrapper' }>
            <nav className={ 'pagination' }>
                { `${ Number(totalGuides).toLocaleString() } ${ I18n[language].guide.toLowerCase() }${ totalGuides === 1 ? '' : 's' }` }
                { prevButton() }
                { pageNumbers }
                { nextButton() }
            </nav>
        </div>
    );
}

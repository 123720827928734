import React, { useEffect } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { createMarkup } from '../../../utilities';
import { fetchWithCache, getLanguage, isMobileGuide, pushGuideEvent } from '../../utilities';
import Navigation from '../Navigation';
import GuideListItems from './GuideListItems';
import GuideArchiveListItems from './GuideArchiveListItems';
import Pagination from './Pagination';

export async function loader({ request }) {
    try {
        const { searchParams } = new URL(request.url);
        const page = searchParams.get('page');
        const date = searchParams.get('date');

        const apiType = isMobileGuide() ? 'mobile-guides' : 'mobile-guides-archive';
        let apiURL = `/api/${ apiType }?page=${ page || 1 }`;
        // if (state.date) apiURL += `&date=${ state.date }`;
        if (date) apiURL += `&date=${ date }`;
        const result = await fetchWithCache(apiURL);

        return result;
    } catch (error) {
        console.error('Error GuideList', error);
        return null;
    }
}

function GuideListWrap({ guides, language }) {
    const ListItems = isMobileGuide() ?
        <GuideListItems guides={ guides } language={ language }/> :
        <GuideArchiveListItems guides={ guides } language={ language }/>;

    const guideListText = isMobileGuide() ? I18n[language].guide_description : I18n[language].guide_description_archive;

    return (
        <section>
            <p className={ 'guide-type-description body-large' }
               dangerouslySetInnerHTML={ createMarkup(guideListText) }></p>
            <div className="guide-empty-message">
                <p className="m-0">
                    <strong>
                        { I18n[language].empty_guides }
                    </strong>
                </p>
            </div>
            <ul className="guide-list active">
                { ListItems }
            </ul>
        </section>
    );
}

export default function GuideList() {
    const { data, meta } = useLoaderData();
    const { total } = meta;
    const [searchParams] = useSearchParams();
    const language = getLanguage(searchParams);

    useEffect(() => {
        document.title = `${ isMobileGuide() ? 'Mobile Guide' : 'Audio guides' } | Whitney Museum of American Art`;
        window.scrollTo({ top: 0 });

        // Google Analytics page load event
        pushGuideEvent({ label: 'overview', value: 'load' });
    }, []);

    return (
        <>
            <Navigation/>
            <GuideListWrap guides={ data } meta={ meta } guidesPerPage={ 30 } language={ language }/>
            <Pagination page={ searchParams.get('page') } totalGuides={ total } listItemsLength={ data.length } language={ language }/>
        </>
    );
}
